import PropTypes from 'prop-types'
import React from 'react'

const Logo = ({ className }) => {
  return (
    <svg
        aria-hidden="true"
        focusable="false"
        data-icon="flowable-initializr"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className={className}
        viewBox="0 0 866.5 141.6"
    >
      <g>
        <g>
          <path className="svg-logo-right"
                d="M862.61,92.47c-2.86,2.97-5.37,4.75-18.04,4.75h-46.72c3.37,9.67,12.56,15.87,23.36,15.87,7.96,0,15.04-3.77,19.56-9.62,.12-.16,11.26,0,23.31,0-1.47,7.98-4.66,13.61-8.38,17.29-9.05,8.94-21.03,15.14-34.74,15.14-26.23,0-47.49-21.31-47.49-47.61s21.26-47.61,47.49-47.61c22.17,0,40.14,13.86,45.53,34.41,1.54,5.88-.21,13.56-3.88,17.37h0Zm-41.4-28.72c-10.8,0-19.99,6.94-23.36,16.61h46.72c-3.37-9.67-12.56-16.61-23.36-16.61h0Z"/>
          <polygon className="svg-logo-right" points="765.33 .05 742.55 0 742.55 135.9 765.33 135.9 765.33 .05 765.33 .05"/>
          <path className="svg-logo-right"
                d="M638.4,89.86h-.02V0h22.37V47.95c7.21-4.5,15.72-7.1,24.83-7.1,26.07,0,47.2,21.28,47.2,47.53s-21.13,47.53-47.2,47.53-46.4-20.48-47.18-46.04h0Zm47.05,22.77c13.37,0,24.21-10.92,24.21-24.38s-10.84-24.38-24.21-24.38-24.21,10.92-24.21,24.38,10.84,24.38,24.21,24.38h0Z"/>
          <path className="svg-logo-right"
                d="M602.8,40.69h22.56v94.92h-22.56v-6.81c-7.28,4.51-15.86,7.11-25.04,7.11-26.29,0-47.61-21.31-47.61-47.61s21.31-47.61,47.61-47.61c9.19,0,17.77,2.6,25.04,7.11v-7.11h0Zm-24.92,71.91c13.49,0,24.42-10.93,24.42-24.42s-10.93-24.42-24.42-24.42-24.42,10.93-24.42,24.42,10.93,24.42,24.42,24.42h0Z"/>
          <path className="svg-logo-left"
                d="M499.81,40.69h22.97c.03,9.75,.05,14.74,.05,14.96v40.12c0,22.21-18.01,40.12-40.35,40.12-11.35,0-21.62-4.48-28.82-12-7.39,7.52-17.47,12-28.82,12-22.34,0-40.35-17.91-40.35-40.12V40.69h23.06c0,9.66,0,14.64,0,14.96v40.12c0,9.49,7.75,17.2,17.29,17.2s17.29-7.7,17.29-17.2V55.65c0-.41,.05-5.4,.15-14.96h22.97v24.61l-.06,30.48c0,9.49,7.75,17.2,17.29,17.2s17.29-7.7,17.29-17.2V55.65c0-.22,.01-5.21,.04-14.96h0Z"/>
          <path className="svg-logo-left"
                d="M328.75,135.9c-26.3,0-47.62-21.31-47.62-47.61s21.32-47.61,47.62-47.61,47.62,21.31,47.62,47.61-21.32,47.61-47.62,47.61h0Zm0-23.14c13.52,0,24.47-10.95,24.47-24.47s-10.96-24.47-24.47-24.47-24.47,10.95-24.47,24.47,10.96,24.47,24.47,24.47h0Z"/>
          <rect className="svg-logo-left" x="251.33" y=".25" width="22.41" height="135.43"/>
          <path className="svg-logo-left"
                d="M194.8,52.27h44.8v22.65h-44.8c-.42,0-1.04-.05-1.85-.15v61.14h-22.46v-.45c-.09,.07,0-.37,0-.75V48.52C169.77,13.06,186.97,1,210.06,.14,210.64,.05,211.23,0,211.83,0h30.23V22.65h-29.91c-10.81,.09-19.21,6.77-19.21,25.23v4.55c.6-.1,1.22-.15,1.85-.15h0Z"/>
          <path className="st0"
                d="M14.59,141.6l28.58-28.59h4.51c7.52,0,13.61-6.1,13.61-13.62v-4.51l36.75-36.76v44.67c0,5.64,4.57,10.21,10.21,10.21s10.21-4.57,10.21-10.21V34.83c.44-3.08-.52-6.32-2.89-8.68-1.77-1.77-4.03-2.76-6.35-2.95-.32-.03-.65-.05-.97-.05H38.83c-5.64,0-10.21,4.57-10.21,10.21s4.57,10.21,10.21,10.21h44.89l-36.75,36.76h-4.73c-7.52,0-13.61,6.1-13.61,13.62v4.73L0,127.31c5.48-16.65,5.48-18.19,5.48-24.51V33.36C5.48,14.94,20.41,0,38.83,0H108.25C126.67,0,141.6,14.94,141.6,33.36V102.8c0,18.42-14.93,33.36-33.35,33.36H38.83c-5.95,0-7.42,0-24.24,5.44h0Z"/>
        </g>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  className: '',
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
