import PropTypes from 'prop-types'
import React from 'react'

const LogoMobile = ({ className }) => {
  return (
    <svg
        aria-hidden="true"
        focusable="false"
        data-icon="flowable-initializr"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className={className}
        viewBox="0 0 145 140"
    >
      <g>
        <g>
          <path className="st0"
                d="M14.59,141.6l28.58-28.59h4.51c7.52,0,13.61-6.1,13.61-13.62v-4.51l36.75-36.76v44.67c0,5.64,4.57,10.21,10.21,10.21s10.21-4.57,10.21-10.21V34.83c.44-3.08-.52-6.32-2.89-8.68-1.77-1.77-4.03-2.76-6.35-2.95-.32-.03-.65-.05-.97-.05H38.83c-5.64,0-10.21,4.57-10.21,10.21s4.57,10.21,10.21,10.21h44.89l-36.75,36.76h-4.73c-7.52,0-13.61,6.1-13.61,13.62v4.73L0,127.31c5.48-16.65,5.48-18.19,5.48-24.51V33.36C5.48,14.94,20.41,0,38.83,0H108.25C126.67,0,141.6,14.94,141.6,33.36V102.8c0,18.42-14.93,33.36-33.35,33.36H38.83c-5.95,0-7.42,0-24.24,5.44h0Z"/>
        </g>
      </g>
    </svg>
  )
}

LogoMobile.defaultProps = {
  className: '',
}

LogoMobile.propTypes = {
  className: PropTypes.string,
}

export default LogoMobile
